import React from "react";
import {Auth0Provider} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";

type Auth0ProviderWithHistoryProps = {
    children: React.ReactNode;
}

export default function Auth0ProviderWithHistory ( {children}: Auth0ProviderWithHistoryProps ) {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN ?? "";
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? "";
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE ?? "";
    const redirectUrl = window.location.origin;
    const navigate = useNavigate();

    const onRedirectCallback = (appState: any) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && clientId && audience )) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            onRedirectCallback={onRedirectCallback}
            authorizationParams={{
                redirect_uri: redirectUrl,
                audience: audience,
            }}

        >
            {children}
        </Auth0Provider>
    );
};

import React, { useEffect, useState } from "react";
import PageLayout from "./PageLayout";
import { IVoucher } from "../models/IVoucher";
import api from '../services/api';
import VoucherForm from "../components/VoucherForm";
import { formatDateForDisplay } from '../utils/dateUtils';

export default function Dashboard() {
    const [vouchers, setVouchers] = useState<IVoucher[]>([]);
    const [editingVoucher, setEditingVoucher] = useState<IVoucher | null>(null);
    const [showUsedVouchers, setShowUsedVouchers] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const getVouchers = React.useCallback(async () => {
        try {
            const response = await api.get('Voucher/get-vouchers');
            if (response.status === 200) {
                setVouchers(response.data);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        getVouchers();
    }, [getVouchers]);

    const filteredVouchers = React.useMemo(() => {
        return showUsedVouchers 
            ? vouchers 
            : vouchers.filter(voucher => !voucher.used);
    }, [vouchers, showUsedVouchers]);

    const handleDelete = async (id: number) => {
        if (!window.confirm('Are you sure you want to delete this voucher?')) {
            return;
        }

        try {
            const response = await api.delete(`Voucher/delete-voucher/${id}`);
            if (response.status === 200) {
                getVouchers();
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleToggleUsed = async (voucher: IVoucher) => {
        try {
            const response = await api.patch(`Voucher/update-voucher/${voucher.id}`, {
                ...voucher,
                used: !voucher.used
            });
            if (response.status === 200) {
                getVouchers();
            }
        } catch (e) {
            console.error(e);
        }
    };

    if (isLoading) {
        return (
            <PageLayout>
                <span className="loading loading-ring loading-lg"></span>
            </PageLayout>
        );
    }

    return (
        <PageLayout>
            {editingVoucher ? (
                <>
                    <button 
                        className="btn btn-ghost mb-4"
                        onClick={() => setEditingVoucher(null)}
                    >
                        ← Back to list
                    </button>
                    <VoucherForm 
                        initialData={editingVoucher}
                        onSuccess={() => {
                            setEditingVoucher(null);
                            getVouchers();
                        }}
                    />
                </>
            ) : (
                <div className="overflow-x-auto">
                    <div className="flex justify-end mb-4">
                        <label className="cursor-pointer label gap-2">
                            <span className="label-text">Show used vouchers</span>
                            <input
                                type="checkbox"
                                className="toggle"
                                checked={showUsedVouchers}
                                onChange={(e) => setShowUsedVouchers(e.target.checked)}
                            />
                        </label>
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Valid Until</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredVouchers.map((voucher) => (
                                <tr key={voucher.id}>
                                    <td>{voucher.name}</td>
                                    <td>{formatDateForDisplay(voucher.validUntil)}</td>
                                    <td>
                                        <span className={`badge ${voucher.used ? 'badge-error' : 'badge-success'}`}>
                                            {voucher.used ? 'Used' : 'Available'}
                                        </span>
                                    </td>
                                    <td className="flex gap-2">
                                        <button 
                                            className="btn btn-ghost btn-sm"
                                            onClick={() => setEditingVoucher(voucher)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                            </svg>
                                        </button>
                                        <button 
                                            className="btn btn-ghost btn-sm"
                                            onClick={() => handleToggleUsed(voucher)}
                                        >
                                            {voucher.used ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" />
                                                </svg>
                                            )}
                                        </button>
                                        <button 
                                            className="btn btn-ghost btn-sm text-error"
                                            onClick={() => handleDelete(voucher.id)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </PageLayout>
    );
}

import React, { useEffect } from 'react';
import './styles/App.css';
import {useAuth0} from "@auth0/auth0-react";
import {Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import {AuthenticationGuard} from "./components/AuthGuard";
import Layout from "./components/Layout";
import Dashboard from "./pages/Dashboard";
import ProfilePage from "./pages/ProfilePage";
import AddVoucherPage from "./pages/AddVoucherPage";
import { setupAxiosAuth } from './services/api';
import { RegistrationGuard } from "./components/RegistrationGuard";

function App() {
    const { isLoading, getAccessTokenSilently } = useAuth0();
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE ?? "";

    useEffect(() => {
        setupAxiosAuth(getAccessTokenSilently, audience);
    }, [getAccessTokenSilently, audience]);

    if (isLoading) {
        return (
            <Layout>
                <span className="loading loading-ring loading-sm"></span>
            </Layout>
        );
    }

    return (
        <Layout>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/profile" element={<AuthenticationGuard component={ProfilePage}/>}/>
                <Route 
                    path="/dashboard" 
                    element={<AuthenticationGuard component={() => (
                        <RegistrationGuard component={Dashboard} />
                    )}/>}
                />
                <Route 
                    path="/add" 
                    element={<AuthenticationGuard component={() => (
                        <RegistrationGuard component={AddVoucherPage} />
                    )}/>}
                />
            </Routes>
        </Layout>
    );
}

export default App;

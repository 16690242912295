import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import Auth0ProviderWithHistory from "./components/Auth0ProviderWithHistory";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Auth0ProviderWithHistory>
                <App/>
            </Auth0ProviderWithHistory>
        </BrowserRouter>
    </React.StrictMode>
);

import React, { useEffect } from "react";
import logo from "../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LoginBtn from "../components/LoginBtn";

export default function HomePage() {
    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/dashboard');
        }
    }, [isAuthenticated, navigate]);

    return (
        <div className={"flex-col"}>
            <img src={logo} alt={"logo"}/>
            {!isAuthenticated && <LoginBtn/>}
        </div>
    );
}

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { IVoucherCreate } from "../models/IVoucherCreate";
import { useNavigate } from "react-router-dom";
import api from '../services/api';
import { IVoucher } from "../models/IVoucher";
import { formatDateForApi, formatDateForInput } from '../utils/dateUtils';

interface VoucherFormProps {
    initialData?: IVoucher;
    onSuccess?: () => void;
}

export default function VoucherForm({ initialData, onSuccess }: VoucherFormProps) {
    const formattedInitialData = initialData ? {
        ...initialData,
        validUntil: formatDateForInput(initialData.validUntil)
    } : undefined;

    const { register, handleSubmit, formState: { errors } } = useForm<IVoucherCreate>({
        defaultValues: formattedInitialData
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (data: IVoucherCreate) => {
        const formattedData = {
            ...data,
            validUntil: formatDateForApi(data.validUntil)
        };
        setIsSubmitting(true);
        setShowSuccess(false);
        setShowError(false);

        try {
            const endpoint = initialData 
                ? `Voucher/update-voucher/${initialData.id}`
                : 'Voucher/add-voucher';
            const method = initialData ? 'patch' : 'post';
            
            const response = await api[method](endpoint, formattedData);

            if (response.status === 200) {
                setShowSuccess(true);
                if (onSuccess) {
                    onSuccess();
                } else {
                    // Redirect to dashboard after 2 seconds
                    setTimeout(() => {
                        navigate('/dashboard');
                    }, 2000);
                }
            } else {
                setShowError(true);
            }
        } catch (e) {
            console.error(e);
            setShowError(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="w-full max-w-lg">
            {showSuccess && (
                <div className="alert alert-success mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    <span>Voucher {initialData ? 'updated' : 'added'} successfully! {!onSuccess && 'Redirecting...'}</span>
                </div>
            )}

            {showError && (
                <div className="alert alert-error mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    <span>Failed to {initialData ? 'update' : 'add'} voucher. Please try again.</span>
                </div>
            )}

            <form onSubmit={handleSubmit(onSubmit)} className="form-control gap-4">
                <div>
                    <label className="label">
                        <span className="label-text">Name</span>
                    </label>
                    <input
                        type="text"
                        className="input input-bordered w-full"
                        {...register("name", {required: true})}
                    />
                    {errors.name && <span className="text-error">This field is required</span>}
                </div>

                <div>
                    <label className="label">
                        <span className="label-text">Valid Until</span>
                    </label>
                    <input
                        type="date"
                        className="input input-bordered w-full"
                        {...register("validUntil", {required: true})}
                    />
                    {errors.validUntil && <span className="text-error">This field is required</span>}
                </div>

                <div>
                    <label className="label">
                        <span className="label-text">Stored At</span>
                    </label>
                    <input
                        type="text"
                        className="input input-bordered w-full"
                        {...register("storedAt", {required: true})}
                    />
                    {errors.storedAt && <span className="text-error">This field is required</span>}
                </div>

                <div>
                    <label className="label">
                        <span className="label-text">Category</span>
                    </label>
                    <input
                        type="text"
                        className="input input-bordered w-full"
                        {...register("category", {required: true})}
                    />
                    {errors.category && <span className="text-error">This field is required</span>}
                </div>

                <div>
                    <label className="label">
                        <span className="label-text">Value</span>
                    </label>
                    <input
                        type="number"
                        className="input input-bordered w-full"
                        {...register("value", {required: true, valueAsNumber: true})}
                    />
                    {errors.value && <span className="text-error">This field is required</span>}
                </div>

                <div>
                    <label className="label">
                        <span className="label-text">Note</span>
                    </label>
                    <input
                        type="text"
                        className="input input-bordered w-full"
                        {...register("note")}
                    />
                </div>

                <div>
                    <label className="label">
                        <span className="label-text">Key</span>
                    </label>
                    <input
                        type="text"
                        className="input input-bordered w-full"
                        {...register("key", {required: true})}
                    />
                    {errors.key && <span className="text-error">This field is required</span>}
                </div>

                <div>
                    <label className="label">
                        <span className="label-text">Pin</span>
                    </label>
                    <input
                        type="text"
                        className="input input-bordered w-full"
                        {...register("pin", {required: true})}
                    />
                    {errors.pin && <span className="text-error">This field is required</span>}
                </div>

                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? (
                        <>
                            <span className="loading loading-spinner"></span>
                            {initialData ? 'Updating...' : 'Adding...'}
                        </>
                    ) : (
                        initialData ? 'Update Voucher' : 'Add Voucher'
                    )}
                </button>
            </form>
        </div>
    );
} 
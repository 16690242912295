import axios from 'axios';
import { showErrorToast } from '../utils/toastUtils';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

// Add response interceptor to handle errors
api.interceptors.response.use(
    response => response,
    error => {
        if (!error.response) {
            showErrorToast('Cannot connect to the server. Please try again later.');
        }
        return Promise.reject(error);
    }
);

export const setupAxiosAuth = (getAccessTokenSilently: any, audience: string) => {
    api.interceptors.request.use(
        async (config) => {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: audience,
                },
            });
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
};

export default api;


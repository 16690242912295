export function formatDateForDisplay(dateString: string): string {
    const date = new Date(dateString + 'Z');
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
}

export function formatDateForApi(dateString: string): string {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0] + 'T00:00:00';
}

export function formatDateForInput(dateString: string): string {
    const date = new Date(dateString + 'Z');
    return date.toISOString().split('T')[0];
} 
import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PageLayout from "./PageLayout";
import api from '../services/api';

interface IUser {
    username: string;
    telegramChatId: string;
}

export default function ProfilePage() {
    const [isRegistered, setIsRegistered] = useState(false);
    const navigate = useNavigate();
    const { register, handleSubmit, setValue } = useForm<IUser>({
        defaultValues: {
            username: '',
            telegramChatId: ''
        }
    });

    const getUser = React.useCallback(async () => {
        try {
            const response = await api.get('User/user');
            if (response.status === 200) {
                const userData = response.data;
                setValue('username', userData.username);
                setValue('telegramChatId', userData.telegramChatId);
                setIsRegistered(true);
            }
        } catch (e) {
            console.error(e);
            setIsRegistered(false);
            // Redirect to profile if not registered
            navigate('/profile');
        }
    }, [setValue, navigate]);

    React.useEffect(() => {
        getUser().then(() => console.log());
    }, [getUser]);

    const registerUser = async (data: IUser) => {
        try {
            const response = await api.post('User/register', { username: data.username });
            if (response.status === 200) {
                getUser();
            }
        } catch (e) {
            console.error(e);
        }
    };

    const updateUser = async (data: IUser) => {
        try {
            const response = await api.patch('User/update', data);
            if (response.status === 200) {
                getUser();
            }
        } catch (e) {
            console.error(e);
        }
    };

    const deleteUser = async () => {
        if (!window.confirm('Are you sure you want to delete your profile? This action cannot be undone.')) {
            return;
        }

        try {
            const response = await api.delete('User/delete');
            if (response.status === 200) {
                setIsRegistered(false);
                getUser();
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <PageLayout>
            <h1>Profile</h1>
            <form onSubmit={handleSubmit(isRegistered ? updateUser : registerUser)}>
                <label className="input input-bordered flex items-center gap-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        className="h-4 w-4 opacity-70">
                        <path
                            d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z"/>
                    </svg>
                    <input 
                        type="text" 
                        {...register('username')}
                        className="grow" 
                        placeholder="Username"
                    />
                    <input
                        type="text"
                        {...register('telegramChatId')}
                        className="grow"
                        placeholder="Telegram Chat Id"
                    />
                </label>
                {!isRegistered ? (
                    <button type="submit" className="btn">Register Profile</button>
                ) : (
                    <div className="flex gap-2">
                        <button type="submit" className="btn">Update Profile</button>
                        <button type="button" className="btn btn-error" onClick={deleteUser}>Delete Profile</button>
                    </div>
                )}
            </form>
        </PageLayout>
    );
}
import React from "react";
import PageLayout from "./PageLayout";
import VoucherForm from "../components/VoucherForm";

export default function AddVoucherPage() {
    return (
        <PageLayout>
            <VoucherForm />
        </PageLayout>
    );
}
import logo from "../assets/logo.svg";
import LogoutBtn from "./LogoutBtn";
import ThemeBtn from "./ThemeBtn";
import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import LoginBtn from "./LoginBtn";

type PageLayoutProps = {
    children: React.ReactNode
}

export default function Layout({children}: PageLayoutProps) {
    const {isAuthenticated} = useAuth0();

    return(
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                {isAuthenticated && <LogoutBtn/>}
                {!isAuthenticated && <LoginBtn/>}
                <ThemeBtn/>
            </header>
            <main className={"App-body"}>
                {children}
            </main>
        </div>
    )
}

import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";

type AuthenticationGuardProps = {
    component: React.ComponentType<any>;

}

export const AuthenticationGuard = ({ component }: AuthenticationGuardProps) => {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <div className="page-layout">
                <span className="loading loading-ring loading-sm"></span>
            </div>
        ),
    });

    return <Component/>;
};

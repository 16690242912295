import React from "react";
import '../styles/PageLayout.css';
import Menu from "../components/Menu";

type PageLayoutProps = {
    children: React.ReactNode
}

export default function PageLayout({children}: PageLayoutProps) {
    return (
        <div className={"Split"}>
            <Menu/>
            {children}
        </div>
    )
}

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../services/api";

type RegistrationGuardProps = {
    component: React.ComponentType<any>;
};

export const RegistrationGuard = ({ component: Component }: RegistrationGuardProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isRegistered, setIsRegistered] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkRegistration = async () => {
            try {
                await api.get('User/user');
                setIsRegistered(true);
            } catch (e) {
                console.error(e);
                navigate('/profile');
            } finally {
                setIsLoading(false);
            }
        };

        checkRegistration();
    }, [navigate]);

    if (isLoading) {
        return (
            <div className="page-layout">
                <span className="loading loading-ring loading-sm"></span>
            </div>
        );
    }

    return isRegistered ? <Component /> : null;
}; 